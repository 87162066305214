.app-title {
    font-size: 4rem;
}
.brock-logo {
    width: 130px;
}
button.button-panel {
    background-color: #ffffff;
    width: 20rem;
    height: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;

    font-size: 2rem;
}
button.button-panel:hover {
    background-color: #f5f5f5;
}
button.button-panel--selected {
    background-color: #f2f2f2;
    box-shadow: none;
}
.button-panel__logo {
    width: 90%;
    max-height: 5.5rem;
}
.button-panel__system-name {
    font-family: 'Open Sans SemiBold', Arial, Helvetica, sans-serif;
    font-size: 1.25rem;
}

button.button-panel--test-system {
    width: 18rem;
    height: 6rem;

    font-size: 1.5rem;
}
button.button-panel--test-system .button-panel__system-name {
    font-size: 1rem;
}
button.button-panel--test-system .button-panel__logo {
    width: 70%;
    max-height: 4rem;
}
.button-panel__test-icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.25rem;
    padding: 0.5rem 1rem;
    color: #ffb600;
}
